export default {
  article_17_title: "Extras - mobile App",
  article_18_title: "Extras - mobile App - Personalisierung",
  article_19_title: "Extras – Stripe-Zahlungen",
  wb_hide_booking_employees: "Mitarbeiter bei der Buchung ausblenden",
  wb_og_image_url: "Open Graph URL (og:image)",
  wb_favicon_url: "Favicon URL",
  wb_elevation: "Schatten",
  wb_show_sign_in_button: 'Schaltfläche "Anmelden" anzeigen',
  wb_show_sign_up_button: 'Schaltfläche "Konto erstellen" anzeigen',
  wb_show_active_button: "Aktive Schaltfläche anzeigen",
  wb_show_company_name: "Unternehmensname anzeigen",
  wb_button_size_type_x_small_title: "XS",
  wb_button_size_type_small_title: "S",
  wb_button_size_type_medium_title: "M",
  wb_button_size_type_large_title: "L",
  wb_button_size_type_x_large_title: "XL",
  wb_button_size_title: "Größe der Schaltfläche",
  auth: "Autorisierung",
  booking_created_no_notifications_label:
    "Informieren Sie nicht über die Erstellung des Besuchs (keine Benachrichtigungen)",
  booking_updated_no_notifications_label:
    "Änderung/Löschung des Besuchs nicht melden",
  booking_deleted_no_notifications_label: "Die Löschung nicht melden",
  booking_no_notifications_tooltip:
    "Der Kunde und der Mitarbeiter erhalten keine E-Mail-, SMS- oder Push-Benachrichtigungen, wenn ein Besuch erstellt/geändert wird. Stattdessen erhalten sie Benachrichtigungen in Form von Erinnerungen (falls aktiviert).",
  recurring_booking: "Regelmäßig wiederholen",
  repeat_every: "Wiederholen Sie alle",
  day: "Tag",
  week: "Woche",
  month: "Monat",
  year: "Jahr",
  day_plural_1: "Tag",
  day_plural_2: "Tage",
  day_plural_3: "Tage",
  day_plural_4: "Tage",
  day_plural_5: "Tage",
  week_plural_1: "Woche",
  week_plural_2: "Wochen",
  week_plural_3: "Wochen",
  week_plural_4: "Wochen",
  week_plural_5: "Wochen",
  month_plural_1: "Monat",
  month_plural_2: "Monate",
  month_plural_3: "Monate",
  month_plural_4: "Monate",
  month_plural_5: "Monate",
  year_plural_1: "Jahr",
  year_plural_2: "Jahre",
  year_plural_3: "Jahre",
  year_plural_4: "Jahre",
  year_plural_5: "Jahre",
  booking_plural_1: "Buchung",
  booking_plural_2: "Buchungen",
  booking_plural_3: "Buchungen",
  booking_plural_4: "Buchungen",
  booking_plural_5: "Buchungen",
  ends_after_occurrences_1: "Abschließen nach",
  ends_after_occurrences_2: "Wiederholungen",
  ends_at_date: "Abschließen am",
  delete_recurring_booking_title: "Löschung eines regelmäßigen Termins",
  delete_recurring_booking_question:
    "Der Termin ist ein regelmäßiger Termin. Was soll entfernt werden?",
  delete_recurring_booking_option_1: "Nur diese eine Veranstaltung",
  delete_recurring_booking_option_2: "Diese Veranstaltung und alle folgenden",
  delete_recurring_booking_option_3:
    "Alle Veranstaltungen, einschließlich derjenigen, die bereits stattgefunden haben",
  all: "Alle",
  payment_2: "Zahlung",
  recurring_booking_ends_info:
    "Die maximale Anzahl der regelmäßigen Termine wurde überschritten. Ändern Sie die Parameter und versuchen Sie es erneut.",
  payment_method_stripe: "Stripe",
  payment_method_cash: "Barzahlung",
  payment_method_transfer: "Überweisung",
  payment_method_card: "Karte",
  payment_method_subscription: "Abonnement",
  payment_method_other: "Andere",
  booking_payment_transaction_create_title: "Bezahlen Sie die Buchung",
  booking_payment_transaction_remove_title: "Zahlung für Besuch löschen",
  payment_transaction_transaction_id: "Transaktionsnummer / Kommentar",
  invalid_time: "Ungültige Zeit",
  add_new_day: "Neuen Tag hinzufügen",
  max_people_title:
    "Maximale Anzahl von Kunden zur gleichen Zeit bei einem Mitarbeiter",
  wb_date_label: "Datumsetikett",
  wb_start_calendar_time: "Zeigen ab",
  wb_end_calendar_time: "Zeigen bis",
  wb_interval_minutes: "Mindestdauer des Termins im Kalender",
  minutes: "min",
  wb_booking_note: "Zusätzliche Informationen",
  wb_require_phone_number: "Fordern Sie eine Telefonnummer im Formular an",
  wb_hide_signup: "Registrierungsoption ausblenden",
  booking_form_custom_fields: "Felder des Buchungsformulars",
  booking_form_custom_fields_equal_note: "Erwarteter Wert",
  clear_filters: "Filter entfernen",
  wb_hide_login: "Anmeldeoption ausblenden",
  wb_hide_header: "Kopfzeile ausblenden",
  wb_hide_footer: "Fußzeile ausblenden",
  wb_add_element: "Formularfeld hinzufügen",
  wb_element_title: "Formularfeld",
  wb_dynamic_form_element_type_1: "Textfeld",
  wb_dynamic_form_element_type_2: "Größeres Textfeld",
  wb_dynamic_form_element_type_3: "Checkbox",
  wb_dynamic_form_element_type_4: "Optionsschaltfläche",
  wb_dynamic_form_element_type_5: "E-Mail-Feld",
  wb_dynamic_form_element_type_6: "Telefonfeld",
  wb_dynamic_form_element_type_7: "Adresse",
  wb_dynamic_form_should_require: "Pflichtfeld",
  wb_dynamic_form_value_equals: "Wert zu überprüfen",
  wb_dummy_text_summary_headline: "Vielen Dank für die Buchung",
  wb_summary_headline: "Titel in Zusammenfassung",
  wb_summary_text: "Titel in der Zusammenfassung",
  wb_view_outlined: "Skizzierte Ansicht",
  wb_view_flat: "Flachansicht",
  wb_view_rounded: "Abgerundete Ansicht",
  wb_select_date_hour: "Text über dem Kalender",
  wb_book_now_label: "Schaltfläche Buchung",
  wb_html_code: "HTML Code",
  wb_zoom: "Vergrößerung",
  wb_longitude: "Längengrad",
  wb_latitude: "Breitengrad",
  wb_map_maker_hide: "Die Stecknadel auf der Karte ausblenden",
  service_location: "Standort",
  service_locations: "Standorte",
  service_location_info:
    "Der Standort definiert die Art und Weise, wie man sich mit Kunden trifft oder verbindet. Diese Information wird auf der Seite zur Bestätigung der Reservierung sichtbar sein und auch in den versendeten E-Mails enthalten sein. Die Standortinformationen werden dem entsprechenden Ereignis hinzugefügt, wenn Sie Calendesk mit externen Kalendern synchronisieren. Sie können mehrere Standorte (online oder offline) hinzufügen, in diesem Fall werden die Kunden gebeten, eine Auswahl zu treffen.",
  service_location_1: "Adresse",
  service_location_2: "Telefon",
  service_location_3: "Google Meet",
  service_location_4: "Andere",
  service_location_5: "Zoom",
  service_location_6: "Microsoft Teams",
  service_location_7: "Skype",
  service_location_8: "WhatsApp",
  select_service_location_label: "Standort auswählen",
  service_location_1_data_label: "Geben Sie die Standortadresse ein",
  service_location_2_data_label: "Telefonnummer eingeben",
  service_location_3_data_label: "Zusätzliche Informationen eingeben",
  wb_image_margin_bottom: "Pixelabstand unter dem Bild",
  wb_text_margin_bottom: "Pixelabstand unter dem Text",
  plugins_fakturownia: "eFakturierung",
  fakturownia_description:
    "eFakturierung ist ein Programm, mit dem Sie die Rechnungsstellung automatisieren können. Calendesk kann alle Zahlungsinformationen direkt auf Ihr Konto im eFakturierung übertragen.",
  fakturownia_description_2:
    "Fügen Sie unten Ihr API-Token und Ihre Kontoadresse hinzu. Diese beiden Werte finden Sie nach dem Einloggen in Ihr Konto im eFakturierung. Sie finden den API-Token unter Einstellungen -> Kontoeinstellungen -> Integration -> API Autorisierungscode. Sie finden Ihre Konto-URL unter Einstellungen -> Kontoeinstellungen.",
  fakturownia_enabled_title: "Das Modul eFakturierung aktivieren",
  fakturownia_send_invoice_title:
    "Senden Sie nach Zahlungseingang automatisch eine Rechnung an die E-Mail-Adresse des Kunden.",
  fakturownia_api_token_title: "API-Token",
  fakturownia_gtu_codes_title: "GTU-Codes",
  fakturownia_account_url_title: "Konto-URL",
  stripe_id_label: "Identifikator in Stripe",
  fakturownia_id_label: "Identifikator im eFakturierung",
  private_notes_for_customer_label: "Private Notizen über den Kunden",
  tax_label: "Steuer",
  wants_invoice_title: "Rechnung",
  fakturownia_validation_error:
    "Calendesk kann sich nicht mit dem Rechnungssystem verbinden. Die angegebenen Daten sind nicht korrekt.",
  feature_not_available_title:
    "Leider ist diese Funktion in Ihrem Plan nicht verfügbar. Ändern Sie Ihren Plan, um alle Calendesk Möglichkeiten freizuschalten.",
  only_subscriptions_label:
    "Die Buchung dieser Dienstleistungen ist nur für Kunden mit einem Abonnement möglich.",
  subscriptions: "Abonnements",
  subscription: "Abo",
  subscriptions_list: "Abo-Liste",
  subscription_payments: "Zahlung",
  each: "Jeder",
  subscription_name: "Name des Abos in Calendesk",
  subscription_name_example: "z. B. monatliche Beratungen",
  invoice_info_1:
    "Aktivieren Sie die Option Rechnungsstellung, wenn Sie möchten, dass das System nach einem korrekten Zahlungsvorgang automatisch eine Rechnung ausstellt (Erfordert die korrekte Konfiguration eines externen Plug-ins für die MwSt.-Fakturierung, z. B. eFakturierung, das Sie in den Erweiterungen finden).",
  wants_invoice_select_title:
    "Eine MwSt.-Rechnung nach Zahlungseingang ausstellen",
  tax_amount_title:
    "Der prozentuale MwSt.-Betrag, der bereits im Preis enthalten ist",
  product_in_stripe: "Produkt in Stripe",
  product_price_in_stripe: "Produktpreis in Stripe",
  product_name: "Produktname in Stripe",
  add_new_stripe_product_title: "Neues Produkt in Stripe hinzufügen",
  add_new_stripe_product_price_title: "Neuen Produktpreis in Stripe hinzufügen",
  subscription_recurring_title: "Wiederkehrende Zahlung",
  service_limits_label:
    "Die Anzahl der möglichen Buchungen im Rahmen dieses Abonnements begrenzen.",
  service_limits_count_label: "Maximale Anzahl von Buchungen",
  service_limits_reset_time: "Die Anzahl der Buchungen regelmäßig zurücksetzen",
  available_services_in_subscription:
    "Verfügbare Dienstleistungen im Abonnement",
  subscription_settings: "Abonnement Einstellungen",
  payment_settings: "Zahlungseinstellungen",
  subscriptions_settings_information:
    "Entscheiden Sie, welche Art von Abonnement Sie Ihren Kunden anbieten möchten. Sie können Dienstleistungen auswählen, für die das Abonnement gilt. Benennen Sie das Abonnement so, dass der Kunde seinen Zweck versteht. Sie können auch entscheiden, ob das Abonnement Einschränkungen für die Buchung ausgewählter Dienstleistungen haben soll (z.B. 10 Buchungen). Einschränkungen können in bestimmten Abständen zurückgesetzt werden (z.B. können Sie 10 Buchungen pro Monat für einen Kunden einrichten).",
  subscription_payment_settings_information:
    "Entscheiden Sie, wie Ihre Kunden das Abonnement bezahlen werden. Dazu benötigen Sie ein Produkt in Stripe und eine Preisfestsetzung, die auf Ihrem Stripe-Konto erstellt wird (klicken Sie auf das Plus-Symbol, um zu erstellen). Die Zahlung kann einmalig oder über die Zeit verteilt sein (z.B. 100 EUR pro Monat). Dank verschiedener Kombinationen von Abonnement- und Zahlungseinstellungen können Sie sehr komplexe Abonnements erstellen (z.B. 10 Buchungen für einen Dienst pro Monat mit einer Jahreszahlung von 999 EUR).",
  bookings_number: "Anzahl der Buchungen",
  recurrent_bookings_reset: "Zyklische Rückstellung alle",
  recurrent_payment_each: "Zyklische Zahlung alle",
  recurrent_each: "Zyklisch alle",
  subscription_success_url: "URL-Weiterleitung nach erfolgter Zahlung",
  subscription_redirect_title: "Optionale Weiterleitungen",
  subscription_success_url_example: "Z.B. https://ihre-website.de/Erfolg",
  subscription_cancel_url: "URL-Weiterleitung nach fehlerhafter Zahlung",
  subscription_cancel_url_example: "Z.B. https://ihre-website.de/Fehler",
  subscription_redirect_question:
    "Wo wird der Benutzer nach einer korrekten oder fehlerhaften Abonnementzahlung weitergeleitet?",
  booking_payment_urls_question:
    "Wo wird der Benutzer nach einer richtigen oder fehlerhaften Buchungszahlung weitergeleitet (diese Einstellung gilt nur für Zahlungen über die Calendesk-Website)?",
  select_subscription: "Abonnement auswählen",
  subscription_active: "Abonnement ist aktiv",
  remove_subscription_not_possible_error_notification:
    "Aktive Abonnements können nicht gelöscht werden.",
  user_subscriptions: "Abonnements",
  active: "Aktiv",
  inactive: "Inaktiv",
  initiated: "Unbezahlt",
  canceled: "Storniert",
  no_user_subscriptions_info:
    "Sie haben Ihren Kunden noch keine Abonnements zugewiesen. Klicken Sie unten, um Ihrem Kunden manuell ein Abonnement zuzuweisen, oder bitten Sie Ihre Kunden, ein Abonnement direkt auf Ihrer Website zu erwerben.",
  go_to_subscriptions: "Zu den Abonnements gehen",
  stripe_subscription_id: "Stripe ID",
  provider_tag: "Methode",
  card: "Karte",
  cash: "Barzahlung",
  transfer: "Überweisung",
  cancel_subscription_success:
    "Die ausgewählten Abonnements wurden korrekt storniert.",
  cancel_subscription_fail:
    "Die ausgewählten Abonnements konnten nicht storniert werden.",
  cancel_succeeded: "Erfolgreich storniert",
  cancel_failed: "Beim Stornieren ist ein Fehler aufgetreten",
  ends_at: "Gültigkeit",
  ends_at_default: "Gültig bis zum Ende des Zeitraums",
  number_of_user_subscriptions: "Anzahl der verkauften Abonnements",
  number_of_simple_store_products: "Anzahl der verkauften Produkte",
  number_of_active_user_subscriptions: "Aktive Abonnements",
  number_of_simple_store_product_transactions: "Alle verkauften Produkte",
  number_of_simple_store_product_transactions_today: "Heute verkaufte Produkte",
  number_of_simple_store_product_transactions_this_week:
    "Verkaufte Produkte in dieser Woche",
  number_of_bookings_by_amount_title: "Einnahmen aus Buchungen",
  number_of_user_subscriptions_by_amount_title: "Einnahmen aus Abonnements",
  number_of_simple_store_product_by_amount_title: "Einnahmen aus Produkten",
  disable_configuration: "Konfigurationsfortschritt ausblenden",
  enable_configuration: "Fortschritt bei der Konfiguration anzeigen",
  quick_configuration: "Schnelle Konfiguration",
  stepper_1_title: "Ihr Kalender ist jetzt online, sehen Sie den Effekt",
  stepper_1_title_a: "Erstellen Sie Ihren Online-Kalender",
  stepper_1_description:
    "Klicken Sie unten, um unser Projekt für den Kalender für Ihre Kunden zu sehen. Es ermöglicht Ihnen, Buchungen für Ihre Dienstleistungen vollständig online entgegenzunehmen. Was Sie hier sehen, ist nur ein Beispiel für die Möglichkeiten von Calendesk. Legen Sie Farben fest, fügen Sie Fotos, Beschreibungen und Lesezeichenim intuitiven Webeditor hinzu.",
  stepper_1_description_a:
    "Klicken Sie unten, um einen Kalender oder eine Website für Ihre Kunden zu erstellen. So können Sie Buchungen für Ihre Dienstleistungen vollständig online entgegennehmen. Legen Sie Farben fest, fügen Sie Fotos, Beschreibungen und Lesezeichen im intuitiven Webeditor hinzu.",
  open_website_button: "Website öffnen",
  edit_website_button: "Website bearbeiten",
  calendar_url_link: "Ihre Kalenderadresse",
  stepper_2_title: "Von wem wird die Buchung angenommen?",
  stepper_2_description:
    "Sie können mehrere Termine wahrnehmen, unabhängig davon, ob Sie selbständig arbeiten oder Mitarbeiter beschäftigen. Ein Mitarbeiterkonto ist ein separater Terminkalender mit eigenem Zeitplan. Wir haben bereits Ihren ersten Mitarbeiter für Sie eingestellt. Dies ist Ihr Konto mit Ihrem Zeitplan, Sie brauchen es nur noch für sich selbst anzupassen.",
  view_employees_account_button: "Mitarbeiterkonten anzeigen",
  stepper_3_title: "Welche Dienstleistungen bieten Sie Ihren Kunden an?",
  stepper_3_description:
    "Listen Sie die Dienstleistungen auf, die Sie Ihren Kunden anbieten möchten. Jeder Dienst kann eine andere Konfiguration haben. Sie können unterschiedliche Preise und Laufzeiten festlegen und die Dienstleistungen verschiedenen Mitarbeitern zuweisen. Wir haben Ihre ersten Dienstleistungen für Sie erstellt, Sie brauchen sie nur noch anzupassen.",
  view_services_button: "Liste der Dienstleistungen anzeigen",
  stepper_4_title: "Werden Sie Online-Zahlungen akzeptieren?",
  stepper_4_description:
    "Nutzen Sie die Vorteile eines praktischen Online-Zahlungssystems und lassen Sie Ihre Kunden für Ihre Dienstleistungen bezahlen. Calendesk-Zahlungen in Kombination mit Stripe sind nicht nur Vorauszahlungen von Kunden, sondern ein fortschrittlicher Zahlungsanbieter, der Ihnen in Kombination mit Calendesk noch viel mehr ermöglicht. Die Konfiguration dauert nur ein paar Minuten und ist für die Verarbeitung von Zahlungen in Calendesk erforderlich.",
  configure_payments_button: "Zahlungen einrichten",
  stepper_5_title: "Zusätzliche Hilfe",
  stepper_5_description:
    "Benötigen Sie weitere Informationen? Haben Sie Probleme beim Einrichten Ihres Systems? Vereinbaren Sie einen Termin für ein kostenloses Beratungsgespräch mit unserem Berater oder nutzen Sie das Hilfezentrum, das Anleitungen in Form von Artikeln und Videos enthält. Wir stehen Ihnen auch für einen Chat zur Verfügung. Klicken Sie einfach auf das Chat-Symbol in der unteren rechten Ecke.",
  meet_us: "Kostenlose Beratung",
  help_center: "Hilfezentrum",
  finish: "Beenden",
  go_to_tab: "Gehe zur Registerkarte",
  with: "mit",
  wb_page_only_with_auth: "Seite nur für eingeloggte Benutzer verfügbar",
  wb_page_only_with_auth_short: "Nur für Eingeloggte",
  wb_menu_button_only_with_auth:
    "Die Schaltfläche ist nur für eingeloggte Benutzer sichtbar",
  wb_remind_password_label: "Link zur Passworterinnerung",
  wb_show_remind_password_link:
    "Aktivieren Sie die Möglichkeit, das Passwort zu erinnern",
  wb_redirect_after_login: "Nach Anmeldung umleiten zu",
  wb_redirect_after_signup: "Nach der Registrierung weiterleiten zu",
  wb_billing_data_checkbox_question: "Angaben zur MwSt.-Rechnung",
  wb_gdpr_fields: "DSGVO-Zustimmung",
  wb_dummy_gdpr_field:
    "Ich akzeptiere die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie",
  wb_add_rgdpr_field_element: "Fügen Sie ein DSGVO-Feld hinzu",
  wb_gdpr_field_name: "Der interne Name des Felds",
  wb_dummy_gdpr_field_name: "DSGVO Zustimmung",
  wb_enable_discounts: "Aktivieren Sie die Möglichkeit, Rabattcodes einzugeben",
  subscriptions_stripe_account_missing_description:
    "Sie benötigen ein vollständig konfiguriertes Stripe-Zahlungskonto, um Abonnements zu verwenden.",
  optional_field: "Optionales Feld",
  select_all: "Alles markieren",
  unselect_all: "Alles abwählen",
  wb_show_billing_data: "Ergänzung der Rechnungsdaten zulassen",
  edit_email: "E-Mail bearbeiten",
  edit_sms: "SMS bearbeiten",
  edit_push: "Push bearbeiten",
  booking_control_url_example:
    "https://ihre-website.calendesk.net/bookings/5de239ca-c1e1-4040-743s-195ef52271aa",
  custom_fields_example:
    '<h3 class="mb0">Zusätzliche Informationen:</h3><p>Ich freue mich auf unser Treffen!</p>',
  usage: "Verwendung",
  insufficient_permissions_error:
    "Sie besitzen keine ausreichenden Befugnisse.",
  additional_auth_required: "Ihre Bank benötigt eine zusätzliche Genehmigung",
  general_payment_error:
    "Es gab ein Problem bei der Zahlung. Möglicherweise unterstützt Ihre Karte keine Abonnements. Wenden Sie sich an Ihre Bank oder wählen Sie eine andere Zahlungsmethode. Wenn Sie Hilfe benötigen, senden Sie uns bitte eine Chat-Nachricht.",
  invoice_data_update_fail:
    "Ihre Rechnungsangaben wurden abgelehnt. Bitte stellen Sie sicher, dass die MwSt.-Nummer und andere Daten, die Sie eingegeben haben, korrekt sind und versuchen Sie es erneut.",
  wb_section_hidden_info:
    'Auf der Registerkarte "Allgemein" können Sie den Abschnitt wiederherstellen.',
  add_to_your_website: "Den Code in die Seite einfügen",
  add_to_your_website_description:
    "Wählen Sie das Element aus, das Sie in Ihre Website einfügen möchten.",
  add_to_your_website_description_widget_1:
    "Fügen Sie den folgenden HTML-Code in Ihre Webseite ein, auf der Ihr Kalender angezeigt werden soll.",
  add_to_your_website_description_widget_2:
    "Fügen Sie den unten stehenden Code in Ihre Website ein, wo Sie eine Schaltfläche zum Öffnen Ihres Kalenders anzeigen möchten.",
  add_to_your_website_description_widget_3:
    "Fügen Sie den unten stehenden Code in Ihre Website ein, wo Sie den Link zum Öffnen Ihres Kalenders anzeigen möchten.",
  widget_1_title: "Fester Bestandteil",
  widget_1_description:
    "Ihr Kalender als fester Bestandteil Ihrer Website. Ihre Kunden werden den Kalender sofort sehen.",
  widget_2_title: "Schaltfläche Fenster",
  widget_2_description:
    "Schaltfläche am unteren Rand Ihrer Seite. Ihre Kunden sehen den Kalender, wenn sie auf die Schaltfläche klicken.",
  widget_3_title: "Fenster aus dem Link",
  widget_3_description:
    "Fügen Sie einen Link zu Ihrer Seite in Form von Text hinzu, der ein Kalenderfenster öffnet.",
  widget_dummy_1: "Termin vereinbaren",
  button_text: "Text auf der Schaltfläche",
  link_text: "Link Text",
  text_color: "Textfarbe",
  background_color: "Hintergrundfarbe",
  copy_code_info: "Code kopieren und auf Ihrer Website einfügen",
  field_only_for_services:
    "Formularfeld nur für ausgewählte Dienstleistungen anzeigen",
  user_preferences: "Einstellungen",
  service_days_title:
    "Weitere Beschränkung der Buchungen auf ausgewählte Tage und Stunden (Buchungen werden durch den Verfügbarkeitsplan der Mitarbeiter und die Beschränkung der zusätzlichen Stunden begrenzt)",
  service_days_description:
    "Sie können die Möglichkeit, diese Dienstleistungen zu buchen, auf bestimmte Tage und Stunden der Woche beschränken. Wenn Ihr Dienstleistungsangebot z. B. nur freitags um 19.00 Uhr stattfindet, wird dem Kunden nur eine Stunde Verfügbarkeit angezeigt, obwohl der Mitarbeiter den ganzen Tag über verfügbar ist. Diese Option ist nicht erforderlich, und wenn Sie sie nicht einstellen, werden die Buchungen gemäß dem Zeitplan des Mitarbeiters angezeigt.",
  disconnect_stripe_account: "Stripe-Konto abschalten",
  disconnect_stripe_account_success: "Das Konto wurde korrekt abgeschaltet.",
  connect_current_stripe_account_question:
    "Ich verfüge bereits über ein Stripe-Konto",
  connect_current_stripe_account_number: "Ihre aktuelle Stripe-ID",
  connect_current_stripe_account_number_error:
    'Geben Sie die Stripe-ID ein oder deaktivieren Sie die Option "Ich verfüge bereits über ein Stripe-Konto", wenn Sie noch kein Stripe-Konto haben.',
  stripe_connect_account_failed:
    "Bei der Verknüpfung Ihres Kontos ist ein Fehler aufgetreten. Sollte der Fehler erneut auftreten, kontaktieren Sie uns bitte im Chat.",
  wb_text_hide: "Text ausblenden",
  wb_radio_add_option: "Auswahloption hinzufügen",
  wb_form_element_type_title: "Typ des Feldes im Formular",
  wb_email_subject: "Der Betreff der E-Mail",
  wb_email_form_success_url:
    "Weiterleitung zur URL nach der Formularübermittlung",
  wb_email_sent: "Inhalt der Nachricht nach Absenden des Formulars",
  form: "Formular",
  wb_section_text: "Text im Abschnitt",
  wb_flexible_form_info:
    "Nach dem Versenden wird das Formular an Ihren E-Mail-Posteingang weitergeleitet, der im Calendesk-Panel definiert ist (Profil -> Registerkarte Ihr Unternehmen). Im Folgenden können Sie den Betreff der Nachricht angeben und die Felder auswählen, die in das Formular aufgenommen werden sollen.",
  wb_section_info:
    "Konfigurieren Sie den ausgewählten Bereich mit Hilfe der unten aufgeführten Elemente.",
  wb_general_tab_info:
    "Konfigurieren Sie unten die allgemeinen Seiteneinstellungen wie Sprache, Google Tag ID, Browsersymbol, Beschreibung für Robots und das Bild, das angezeigt wird, wenn Sie den Link z. B. auf sozialen Medien teilen. Sie können die URLs für die Bilder aus der Bildergalerie kopieren, indem Sie auf die Bildvorschau klicken (Sie sehen die URL am unteren Rand des Bildes).",
  wb_pages_tab_info:
    'Wenn Sie auf den Namen der Unterseite unten klicken, können Sie zu ihr wechseln und ihre Abschnitte bearbeiten. Wenn Sie den Namen oder die Adresse der Unterseite ändern möchten, klicken Sie auf das Symbol neben dem Namen der Unterseite und wählen Sie "Bearbeiten".',
  buttons: "Schaltflächen",
  wb_form_label_info: "Etikett (Informationen in dem Feld, z. B. Name)",
  wb_form_description_info:
    "Beschreibung (Informationen über dem Feld, z. B. Ihr Name)",
  click_to_edit: "Zum Bearbeiten klicken",
  unavailable_booking_slots: "Kalender Sperren",
  all_day: "Ich möchte volle Tage einstellen",
  all_day_tooltip:
    "In der oberen Leiste des Kalenders und in der Monatsansicht sehen Sie Termine, die als volle Tage erstellt wurden.",
  block_calendar: "Kalender sperren",
  integrations: "Integrationen",
  google_calendar_and_meet: "Google Kalender + Google Meet",
  zoom: "Zoom",
  zoom_connect_info:
    "Verknüpfen Sie Ihr Zoom-Konto mit Calendesk. Durch die Integration von Zoom mit Calendesk und die entsprechende Einstellung des Standorts für Ihren Service enthalten Ihre bestätigten Buchungen einen individuellen Terminlink für Sie und Ihren Kunden.",
  zoom_connect_info_short:
    "Der führende Anbieter für die Durchführung von Online-Meetings.",
  zoom_connect_button_title: "Zoom verbinden",
  zoom_disconnect_info:
    'Die Verbindung mit Zoom ist aktiv. Klicken Sie auf "Trennen", um die Verbindung zu trennen.',
  zoom_disconnect_button_title: "Zoom beenden",
  zoom_auth_success_title: "Korrekte Zoom-Autorisierung",
  zoom_auth_success_description:
    "Vielen Dank, Sie haben Ihr Zoom-Konto korrekt autorisiert. Gehen Sie zu Ihren Dienstleistungen, um einen Besprechungsort mit Zoom auszuwählen.",
  zoom_auth_fail_description:
    "Es gab ein Problem bei der Zoom-Autorisierung. Bitte versuchen Sie es erneut.",
  zoom_disconnect_dialog_info:
    "Sind Sie sicher, dass Sie die Zoom-Integration entfernen möchten?",
  zoom_disconnect_success_message:
    "Die Integration mit Zoom wurde korrekt entfernt.",
  settings_alphanumeric_sms_sender_title: "Eigener SMS-Absendername",
  calendesk_name_example: "z.B. Calendesk",
  settings_alphanumeric_sms_sender_input_title: "SMS-Absender",
  settings_alphanumeric_sms_sender_validation_error:
    "Das Format des Absenders ist fehlerhaft. Es werden Buchstaben, Leerzeichen und Zahlen akzeptiert. Mindestens ein Buchstabe. Maximal 11 Zeichen.",
  settings_alphanumeric_sms_sender_description:
    "Ihr eigener SMS-Absendername in Nachrichten an Kunden. Ersetzen Sie eine langweilige und zufällige Telefonnummer durch Ihren eigenen Namen und zeigen Sie sich von Ihrer besten Seite. Der Name muss mindestens einen Buchstaben enthalten. Zulässige Werte sind Buchstaben (ohne diakritische Zeichen), Zahlen und Leerzeichen. Maximal 11 Zeichen.",
  enable_package: "Paket einschalten",
  package_not_available_title:
    "Sie benötigen ein zusätzliches Paket, um diese Funktion zu nutzen. Gehen Sie zum Shop, um das Paket einzuschalten.",
  subscription_update_information:
    "Wenn Sie während des Abrechnungszeitraums Ihren Abonnementplan ändern oder zusätzliche Pakete aktivieren, wird der Zahlungsanbieter die Beträge neu berechnen und Ihnen nur die erforderliche Differenz berechnen.",
  wb_only_services_with_subscriptions:
    "Nur Dienstleistungen anzeigen, für die der eingeloggte Kunde aktive Abonnements hat",
  wb_use_booking_cart: "Den Buchungskorb verwenden",
  wb_selected_services: "Text im Korb",
  wb_count_places_in_dates:
    "Alle verfügbaren Termine bei den Daten anzeigen (Gruppenbuchungen)",
  number_of_sms_messages: "Anzahl der SMS-Nachrichten",
  api: "API",
  api_description:
    "Unbegrenzte Integrationsmöglichkeiten mit Calendesk dank der RESTful-API-Verbindung. Verwenden Sie die Calendesk-API für individuelle Lösungen.",
  generate_api_key_button: "API Schlüssel generieren",
  delete_api_key: "API Schlüssel entfernen",
  api_key: "API Schlüssel",
  open_api_documentation: "API Dokumentation",
  tenant_name: "Tenant name",
  service_limits: "Weitere Einschränkungen des Dienstleistungsangebots",
  service_availability_title:
    "Verfügbarkeit des Dienstleistungsangebots für die Kunden",
  choose_availability_schedule: "Verfügbarkeitsplan auswählen",
  add_new_availability_schedule: "Neuen Verfügbarkeitsplan hinzufügen",
  update_availability_schedule: "Verfügbarkeitsplan bearbeiten",
  add_schedules_with_plus:
    "Keine Verfügbarkeitspläne verfügbar. Fügen Sie einen neuen Verfügbarkeitsplan hinzu, indem Sie auf der rechten Seite auf '+' klicken",
  schedule_name: "Name des Verfügbarkeitsplans",
  display_schedule: "Verfügbarkeitsplan bearbeiten",
  delete_schedule: "Verfügbarkeitsplan entfernen",
  weekdays: "Wochentage",
  schedule_dates: "Daten überschreiben Wochentage",
  schedule_dates_info:
    "Fügen Sie Daten hinzu, an denen sich die Verfügbarkeit im Zusammenhang mit den Wochenstunden ändern wird",
  add_new_date: "Datum hinzufügen",
  hours_availability_info: "Verfügbare Stunden",
  select_availability_dates_info: "1. Verfügbarkeitstage auswählen",
  select_availability_hours_info: "2. Verfügbarkeitsstunden auswählen",
  fill_availability_schedules:
    "Wählen Sie die Tage aus, die in Ihrem Verfügbarkeitsplan verfügbar sein werden",
  plan_unavailable_error:
    "Ihre aktuellen Calendesk-Einstellungen erlauben es Ihnen nicht, diesen Plan zu wählen. Wenn Ihr Probezeitraum abgelaufen ist, kontaktieren Sie uns bitte, damit wir ihn verlängern können.",
  value: "Wert",
  no_data: "Daten fehlen",
  created_by: "Erstellt von",
  updated_by: "Letzte Änderung durch",
  updated_at: "Aktualisiert",
  my_booking: "Meine Buchungen",
  payment_user_is_required:
    "Es ist kein Kunde zugeordnet. Für eine Zahlung ist ein aktiver Kunde erforderlich.",
  promo_number_of_months: "Anzahl der Monate, für die der Preis wichtig ist:",
  wb_newsletter_success_url:
    "Newsletter URL - Weiterleitung nach der Anmeldung",
  wb_account_activation_success_url:
    "Kontoaktivierung URL – Weiterleitung nach der Aktivierung",
  wb_show_name_surname: "Vollständigen Namen im Formular anzeigen",
  wb_show_phone_number: "Telefonnummer im Formular anzeigen",
  wb_hide_time_zone: "Zeitzonenauswahl ausblenden",
  wb_add_code_to_website_head:
    "Kopieren Sie den folgenden Code und fügen Sie ihn in den Code Ihrer Website zwischen den Tags ein  „<head>” und „</head>”.",
  wb_add_code_to_website_frame:
    "Kopieren Sie den unten stehenden Code und fügen Sie ihn in den Code Ihrer Website ein, auf der Sie Ihren Kalender anzeigen möchten.",
  wb_height_desktop: "Höhe auf dem Computer in Pixel",
  wb_height_mobile: "Höhe auf Smartphones in Pixel",
  google_calendar_add_title:
    "Buchungen und Sperrungen an den Google Kalender senden",
  google_calendar_add_info:
    "Wählen Sie den Google-Kalender, zu dem neue Buchungen und Kalendersperren aus Calendesk hinzugefügt werden sollen.",
  select_calendar: "Kalender auswählen",
  calendar_watch_title: "Termine herunterladen",
  google_calendar_watch_info:
    "Wählen Sie aus, aus welchen Google-Kalendern wir Termine herunterladen sollen. An ihrer Stelle werden im Calendesk-Kalender Sperren erstellt, um Ihre Verfügbarkeit zu deaktivieren.",
  select_calendars: "Kalender auswählen",
  contact_sales: "Kontakt",
  notification_contains_special_characters: "Meldung enthält Sonderzeichen",
  replace_invalid_sms_characters_checkbox:
    "Sonderzeichen in SMS-Benachrichtigungen entfernen (kann sich auf die Anzahl der gesendeten SMS auswirken)",
  sms_number_of_messages_info:
    'Die Gesamtzahl der gesendeten SMS-Nachrichten kann nach Neuberechnung der Länge der Variablen und unter Berücksichtigung der Option "Sonderzeichen in SMS-Benachrichtigungen entfernen" unter Einstellungen/Plattform variieren.',
  blackout_date_all_employees_warning:
    "Blockieren Sie den Kalender für alle Mitarbeiter.",
  no_limit: "Unbeschränkt",
  no_available_time_slots:
    "Keine verfügbaren Stunden. Versuchen Sie, das Datum zu ändern oder einen anderen Dienst zu wählen.",
  no_available_time_slots_v2:
    "Keine verfügbaren Zeitfenster am ausgewählten Tag. Versuchen Sie, das Datum, den Standort zu ändern oder einen anderen Service auszuwählen.",
  select_availability_service_info:
    "Klicken Sie auf die gewünschte Stunde, um sie in das Buchungsformular zu übertragen.",
  select_service_error:
    "Wählen Sie eine Dienstleistung aus, um Termine zu suchen.",
  booking_awaiting_payment_warning:
    "Die Buchung wird gelöscht, wenn sie nicht bezahlt wird. Zeit zu zahlen:",
  booking_reminder_setting_info: "Versandzeit vor der Buchung",
  integrations_google_calendar_id_help:
    'Siehe Artikel "Wie wird der richtige Kalender ausgewählt?"',
  booking_date_in_the_past_warning:
    "Sie versuchen, ein Ereignis zu bearbeiten, das bereits vergangen ist. E-Mail/SMS/Push-Benachrichtigungen und die Synchronisation mit externen Kalendern werden nicht verfügbar sein.",
  update_draft_name_title: "Vorlage umbenennen",
  draft_name: "Name der Vorlage",
  wb_duplicate_section_title:
    "Abschnitt auf eine ausgewählte Unterseite kopieren",
  wb_duplicate_section_info:
    "Wählen Sie die Unterseite, auf die Sie den Abschnitt kopieren möchten.",
  wb_duplicate_section_success_message: "Abschnitt korrekt erstellt.",
  booking_customer_link: "Link zur Kundenbuchung",
  autocomplete_hint: "Schreiben Sie, um zu suchen",
  redirect_to_external_url: "Umleitung zu einer externen URL",
  url_address: "URL",
  url_example: "z.B. https://ihre-seite.de",
  open_in_new_tab: "In neuer Registerkarte öffnen",
  push_all_future_events_to_calendar_checkbox:
    "Senden Sie jetzt alle zukünftigen Buchungen und Kalendersperren, die Sie in Calendesk geplant haben.",
  google_calendar_id: "Google Kalender-ID",
  set_google_calendar_id: "Kalender-ID festlegen",
  google_calendar_id_info:
    "Wenn Sie mehrere Google-Kalender in einem Konto haben, können Sie hier die ID-Nummer des Kalenders einstellen, den Sie verwenden möchten. Wenn Sie nichts eingeben, verwenden wir Ihren Google-Hauptkalender.",
  google_calendar_example: "z.B. c_8gdd84889nd9nxod4@group.calendar.google.com",
  additional_options: "Weitere Optionen",
  calendar_view: "Kalender",
  require_billing_data_checkbox: "Angaben zur MWSt-Rechnung erforderlich",
  only_company_invoice_data_type_checkbox:
    "Fordern Sie vollständige Daten für die MWSt-Rechnung (Umsatzsteuernummer, Firmenname, Adresse) an",
  you_can_edit_admin_password_info:
    "Das Passwort des Administrators kann nicht bearbeitet werden.",
  can_not_find_booking:
    "Die Buchung kann nicht geladen werden, sie ist wahrscheinlich gelöscht worden.",
  can_not_find_unavailable_booking_slot:
    "Die Kalendersperre kann nicht geladen werden, sie wurde wahrscheinlich gelöscht.",
  price_additional_info:
    "Der Mindestbetrag für die Bearbeitung von Zahlungen beträgt €0,50 (bzw. den Dollar-Gegenwert von $0,50).",
  service_types_additional_info:
    "Sie können für jede Dienstleistungsoption eine andere Dauer und einen anderen Preis festlegen.",
  service_settings: "Einstellungen der Dienstleistungen",
  service_employee_title: "Wer wird die Dienstleistung durchführen?",
  service_additional_break_info: "Zusätzliche Pause vor oder nach der Buchung",
  service_max_people_info: "Gruppenbuchungen aktivieren",
  stripe_payments_warning:
    "Die Stripe-Integration ist noch nicht aktiv. Verbinden Sie Stripe, um Zahlungen vollständig zu akzeptieren.",
  advance_settings_label: "Erweiterte Einstellungen anzeigen",
  service_availability_employees_info:
    "Die Mitarbeiter verwenden ihre Verfügbarkeitspläne.",
  service_availability_service_info:
    "Die Dienstleistung hat einen einzigen Verfügbarkeitsplan für alle Mitarbeiter.",
  service_availability_employees_schedule_title:
    "Verfügbarkeitsplan der Mitarbeiter",
  service_availability_service_schedule_title: "Dienstleistungsplan",
  service_free_option: "Kostenlos",
  service_max_people_short_info: "Anzahl der Plätze",
  required_capability_info:
    "Um unsere Kunden zu schützen, müssen wir Ihr Konto verifizieren, um diese Funktion freizuschalten. Es ist ein Standardverfahren. Wir haben die Informationen erhalten und arbeiten bereits daran. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns.",
  required_permission_info:
    "Sie verfügen nicht über die erforderlichen Berechtigungen, um diesen Vorgang durchzuführen. Bitte wenden Sie sich an Ihren Administrator.",
  old_employee_schedule_label: "Alte Version des Arbeitszeitplans",
  old_employee_schedule_label_tooltip:
    "Wechseln Sie jetzt zu gemeinsamen Verfügbarkeitsplänen, die Ihnen viel mehr Möglichkeiten bieten.",
  employee_schedule_hint: "z.B. Hans Beschäftigt - Verfügbarkeitsplan",
  duplicate_service: "Dienstleistung duplizieren",
  show: "Anzeigen",
  wb_custom_code: "Benutzerdefinierte Skripte",
  custom_code_info:
    "Benutzerdefinierte Skripte werden nur auf der veröffentlichten Seite angezeigt.",
  wb_show_service_column: "Spalte mit Dienstleistungsname anzeigen",
  wb_require_billing_data: "Angaben zur MwSt-Rechnung anfordern",
  private_notes: "Private Notizen",
  signup_onboarding_title_1: "Willkommen bei Calendesk!",
  signup_onboarding_subtitle_1:
    "Nur 5 Minuten Einrichtung ersparen Ihnen später stundenlange Arbeit. Wir führen Sie in ein paar einfachen Schritten durch die wichtigsten Calendesk-Einstellungen.",
  signup_onboarding_title_2: "Mitarbeiter",
  signup_onboarding_subtitle_2:
    "Mitarbeiter können Online-Buchungen für ausgewählte Dienstleistungen vornehmen und einen separaten Zugriff auf das Calendesk-Panel erhalten. Jeder Mitarbeiter kann seinen eigenen Verfügbarkeitsplan haben.",
  signup_onboarding_title_3: "Dienstleistungen",
  signup_onboarding_subtitle_3:
    "Die Dienstleistungen werden im Buchungskalender angezeigt. Fügen Sie jetzt mindestens eine Dienstleistung aus Ihrem Angebot hinzu. Später können Sie weitere Dienste hinzufügen, in Kategorien gruppieren und die Details bearbeiten.",
  signup_onboarding_title_4: "Verfügbarkeit des Kalenders",
  signup_onboarding_subtitle_4:
    "Der Verfügbarkeitsplan zeigt an, wann Sie verfügbar sind und wann Ihre Kunden sich für einen Termin mit Ihnen anmelden können. Der Verfügbarkeitsplan kann Mitarbeitern oder Dienstleistungen zugewiesen werden.",
  signup_onboarding_title_5: "Integrationen",
  signup_onboarding_subtitle_5:
    "Durch die Integration von Calendesk mit externen Tools können Sie Ihre Arbeitsabläufe optimieren und effizienter gestalten.",
  signup_onboarding_title_6: "Herzlichen Glückwunsch!",
  signup_onboarding_subtitle_6:
    "Alles bereit, um Buchungen von Ihren Kunden anzunehmen.",
  signup_onboarding_card_title_1: "Über Sie",
  signup_onboarding_card_subtitle_1:
    "Fügen Sie ein paar grundlegende Informationen hinzu.",
  signup_onboarding_card_title_2: "Wer wird Calendesk verwenden?",
  signup_onboarding_card_subtitle_2:
    "Fügen Sie die Personen hinzu, die Sie beschäftigen oder mit denen Sie zusammenarbeiten.",
  signup_onboarding_card_title_3: "Was werden Sie den Kunden anbieten?",
  signup_onboarding_card_subtitle_3:
    "Geben Sie den Namen der Dienstleistungen, den Preis und die Dauer ein. Legen Sie fest, wer für die Durchführung der Dienstleistung verantwortlich sein soll.",
  signup_onboarding_card_title_4: "Standard-Verfügbarkeitszeitplan",
  signup_onboarding_card_subtitle_4:
    "Wir weisen Ihren Mitarbeitern einen Standard-Verfügbarkeitsplan zu. Später können Sie sie leicht anpassen, Ausschlüsse, Feiertage oder Ferien eintragen.",
  signup_onboarding_card_title_5: "Verfügbare Integrationen",
  signup_onboarding_card_subtitle_5:
    "Nachfolgend finden Sie eine kurze Vorschau der verfügbaren Integrationen. Sie können sie problemlos im Calendesk-Panel verbinden.",
  signup_onboarding_card_title_6: "Ihr Kalender ist jetzt online",
  signup_onboarding_card_subtitle_6:
    "Wir haben einen Vorschlag für Ihren Buchungskalender erstellt. Mit Calendesk können Sie die URL, Farben, Abschnitte und Unterseiten ganz einfach anpassen oder den Kalenderstil ändern.",
  signup_onboarding_services_employees_label:
    "Wer wird die Dienstleistung durchführen?",
  signup_onboarding_domain_label:
    "Die URL kann später problemlos im Webeditor geändert werden.",
  signup_onboarding_employee_is_public_info:
    "Ein Mitarbeiterkonto kann Buchungen von Kunden annehmen, aber es kann auch von Ihrem Mitarbeiter genutzt werden, um sich einzuloggen und z. B. den Kalender einzusehen. Sie können diese Option später ganz einfach ändern.",
  online_bookings: "Online Buchungen",
  integrations_category_calendars: "Kalender",
  integrations_category_video: "Videoanruf",
  integrations_category_payments: "Zahlungen",
  integrations_category_accounting: "Abrechnung",
  integrations_category_automation: "Automatisierung",
  integrations_category_analytics: "Analytik",
  integrations_category_www: "Webseite",
  coming_soon_info:
    "Option in Kürze verfügbar. Wir werden Sie informieren, sobald wir die Arbeiten beendet haben.",
  go_to_services_button: "Zu den Dienstleistungen gehen",
  start_integration_label: "Integration beginnen",
  integrations_for_employee_tab: "Integration der Mitarbeiter",
  integrations_for_global_tab: "Globale Integrationen",
  zapier: "Zapier",
  zapier_description:
    "Zapier ermöglicht den Austausch von Informationen zwischen Applikationen, die scheinbar nichts miteinander zu tun haben. So können Sie Calendesk-Daten an andere Applikationen weitergeben und dort entsprechende Aktionen durchführen.",
  zapier_description_short:
    "Übertragen Sie Daten aus Calendesk an mehr als 4.000 Applikationen!",
  google_tag_manager: "Google Tag Manager",
  google_tag_manager_description:
    "Verwalten Sie alle Integrationen auf Ihrer Website an einem Ort. Google Tag Manager ist ein effizientes Tool, mit dem Sie jeden beliebigen Code zu Ihrem Calendesk-Kalender oder Ihrer Website hinzufügen können.",
  google_tag_manager_description_short:
    "Verwalten Sie alle Integrationen auf Ihrer Website an einem Ort.",
  calendesk_widget: "Calendesk-Plugin",
  calendesk_widget_description:
    "Platzieren Sie das Calendesk-Buchungssystem auf einer beliebigen Webseite. Entscheiden Sie, ob Sie das System als Seitenelement, als Pop-up oder vielleicht als permanente, sichtbare Schaltfläche am unteren Rand der Seite verwenden möchten. Gehen Sie zum Seitengenerator und kopieren Sie den generierten Plugin-Code. Fügen Sie es auf Ihrer Website ein und lassen Sie Ihre Kunden direkt auf Ihrer Website Termine vereinbaren.",
  calendesk_widget_description_short:
    "Integrieren Sie das Calendesk-Buchungssystem in eine beliebige Website.",
  google_analytics: "Google Analytics",
  google_analytics_description:
    "Google Analytics stellt Ihnen die kostenlosen Tools zur Verfügung, die Sie benötigen, um Ihre Geschäfts- und Websitedaten an einem Ort zu analysieren, so dass Sie intelligentere Entscheidungen treffen können.",
  microsoft_teams: "Teams",
  microsoft_skype: "Skype",
  microsoft_outlook: "Outlook",
  work_schedule: "Arbeitsplan",
  category_default_name: "Beratung",
  root_email_info:
    "Nach der Konfiguration werden Sie Ihre Haupt-E-Mail-Adresse ändern.",
  email_must_be_unique:
    "E-Mail-Adressen sind optional und dürfen nicht wiederholt werden.",
  value_must_be_unique: "Der eingegebene Wert kann nicht wiederholt werden.",
  billing_data: "Daten zur Abrechnung",
  global_error_title: "Leider gab es ein Problem beim Laden der Calendesk-App.",
  global_error_subtitle:
    "Dieses Problem tritt in der Regel aus zwei Gründen auf:",
  global_error_description_1:
    "1. <b>Internetverbindung</b>. Versuchen Sie, Ihre Internetverbindung zu überprüfen und die Seite neu zu laden..",
  global_error_description_2:
    "2. <b>Calendesk-Systemaktualisierung</b>. Von Zeit zu Zeit nehmen wir Aktualisierungen am System vor, damit es besser funktioniert. Versuchen Sie, die Seite in ein paar Minuten neu zu laden.",
  main_page_button_title: "Startseite",
  reload: "Aktualisieren",
  wb_show_service_description: "Dienstleistungsbeschreibung anzeigen",
  wb_hide_booking_time_to: "Endzeit der Buchung ausblenden",
  wb_redirect_logo: "Weiterleitung mit dem Logo",
  collapse_all: "Alle ausblenden",
  expand_all: "Alle erweitern",
  service_status: "Status der Dienstleistung",
  availability: "Verfügbarkeitsplan",
  add_availability_schedule: "Verfügbarkeitsplan hinzufügen",
  availability_list_info:
    "Verwenden Sie Verfügbarkeitspläne, um Ihren Kunden verfügbare Buchungstermine anzuzeigen. Die Pläne können den Mitarbeitern, direkt den Dienstleistungen oder beiden gleichzeitig zugewiesen werden. Verfügbarkeitspläne können geteilt werden. Jeder Mitarbeiter kann eine Plan verwalten, wenn er diesem zugewiesen ist oder ihn selbst erstellt hat.",
  assigned_to_employees: "Mitarbeitern zugeordnet",
  assigned_to_services: "Dienstleistungen zugeordnet",
  assign_to_employees: "Den Mitarbeitern zuweisen",
  assign_to_services: "Den Dienstleistungen zuweisen",
  activate_work_schedule: "Mir zuweisen",
  section_admin_availability_list: "Übersicht / Verfügbarkeitsplan",
  test_availability: "Verfügbarkeit testen",
  employee_assigned_to_services_title:
    "Dienstleistungen, die von einem Mitarbeiter ausgeführt werden",
  employee_assigned_to_services_info:
    "Eine Buchung für eine Dienstleistung setzt voraus, dass ein Mitarbeiter zur Ausführung der Dienstleistung zugewiesen wird. Unten können Sie die Dienstleistungen auswählen, die diesem Mitarbeiter zugewiesen werden sollen.",
  wb_column_width: "Spaltenbreite in Prozent, z.B. 50",
  max_booking_time: "Buchung den Dienst frühestens",
  max_user_bookings: "Die maximale Anzahl an Buchungen für einen Kunden",
  max_user_bookings_checkbox:
    "Begrenzen Sie die Anzahl der Buchungen für Kunden",
  create_user_subscription_button_title: "Abonnement zuweisen",
  create_user_subscription_info_1:
    "Sie können jede Art von Abonnement erstellen, ohne Kunden in den Kauf einzubeziehen. Wiederkehrende Abonnements mit Buchungslimits verlängern ihre Limits gemäß ihren Einstellungen. Diese Abonnements bleiben aktiv, bis Sie sie manuell kündigen. Im Fall von einmaligen kostenpflichtigen Abonnements (Paketen) bleiben sie aktiv, bis der Kunde ihre Ressourcen verwendet oder sie manuell gekündigt werden. Das System überwacht keine Zahlungen für manuelle Abonnements/Pakete und storniert solche Abonnements im Falle einer Nichtzahlung nicht. Sie können Zahlungen für manuelle Abonnements auf der Registerkarte Kunden/Abonnements verwalten. Sie können unten auch Ihre erste Abonnementzahlung erstellen.",
  create_user_subscription_info_2:
    "Wählen Sie das Abonnement aus, das Sie einem Kunden zuweisen möchten. Außerdem können Sie das Erstellungs (Verkaufs) und Enddatum des Abonnements angeben. Das Erstellungsdatum muss in der Vergangenheit liegen. Fügen Sie optional Zahlungsinformationen hinzu, wenn Sie diese außerhalb von Calendesk verarbeitet haben.",
  create_subscription_payment: "Ich möchte eine Zahlung erstellen",
  create_payment: "Zahlung erstellen",
  user_subscription_id: "Kundenabonnement-ID",
  booking_id: "Buchungs-ID",
  create_payment_for_user_subscription:
    "Erstellen Sie eine Zahlung für ein Kundenabonnement",
  create_payment_invoice_info:
    "Die Mehrwertsteuerrechnung wird automatisch ausgestellt, wenn Sie eine ordnungsgemäße Integration mit dem Rechnungssystem konfiguriert haben und die Option gewählt haben, Mehrwertsteuerrechnungen bei Zahlungseingang auszustellen.",
  notification_usage_not_available_info:
    'Sie haben nicht genügend Benachrichtigungen, um Nachrichten zu senden. Gehen Sie zum Tab "Benachrichtigungen", um Ihr Konto aufzufüllen.',
  buy_notifications: "Benachrichtigungen kaufen",
  notifications_sent: "Ihre Nachricht wurde verschickt.",
  employee_names: "Vor- und Nachname des Mitarbeiters",
  pay_with_subscription_error_message:
    "Sie können nicht mit dem ausgewählten Abonnement bezahlen - seine Grenzen sind erschöpft, es ist inaktiv oder sein Erstellungsdatum liegt nach dem Buchungsdatum.",
  subscription_created_at: "Erstellungsdatum des Abonnements",
  subscription_ends_at: "Enddatum des Abonnements",
  wants_create_subscription_end_date_checkbox:
    "Ich möchte ein Enddatum für das Abonnement festlegen",
  manage: "Verwalten",
  status_and_payment: "Status/Zahlung",
  duplicate_booking: "Duplizieren Sie die Buchung",
  edit_booking: "Bearbeiten Sie die Buchung",
  edit_unavailable_booking_slot: "Bearbeiten Sie die Kalendersperre",
  delete_unavailable_booking_slot: "Löschen Sie die Kalendersperre",
  disable_indexing_by_robots:
    "Suchmaschinen-Robots nicht erlauben, die Seite für den Kauf dieses Abonnements zu indexieren",
  show_employees_working_hours:
    "Zeigen Sie die Verfügbarkeitspläne der Mitarbeiter im Kalender an",
  integrations_category_employees: "Kalender und Videokonferenzen",
  integrations_google_tools_title: "Google-Dienste",
  integrations_microsoft_tools_title: "Microsoft-Dienste",
  outlook_calendar_connect_button_title:
    "Beginnen Sie mit der Integration mit Microsoft",
  google_calendar_connect_button_title:
    "Beginnen Sie mit der Integration mit Google",
  google_calendar_description:
    "Zugriff auf Ihre Calendesk-Buchungen und Sperren immer griffbereit in Ihrem persönlichen Google-Kalender. Entscheiden Sie, welchen Google-Kalendern Sie folgen möchten, um Ihren Verfügbarkeitskalender automatisch für Kunden in Calendesk zu sperren.",
  outlook_calendar_description:
    "Zugriff auf Ihre Calendesk-Buchungen und Sperren immer griffbereit in Ihrem persönlichen Outlook-Kalender. Entscheiden Sie, welchen Outlook-Kalendern Sie folgen möchten, um Ihren Verfügbarkeitskalender automatisch für Kunden in Calendesk zu sperren.",
  microsoft_teams_description:
    "Legen Sie den Standort Ihrer Dienstleistung auf Teams in Calendesk fest, damit das Calendesk-System bei jeder Buchung einen individuellen Link zu dem Termin erstellt. Der Link wird an Sie und Ihren Kunden weitergeleitet, sobald die Buchung erstellt wurde.",
  microsoft_skype_description:
    "Legen Sie den Standort Ihrer Dienstleistung auf Skype in Calendesk fest, damit das Calendesk-System bei jeder Buchung einen individuellen Link zu dem Termin erstellt. Der Link wird an Sie und Ihren Kunden weitergeleitet, sobald die Buchung erstellt wurde.",
  whatsapp: "WhatsApp",
  whatsapp_description:
    "Verknüpfen Sie Ihre WhatsApp-Nummer mit Calendesk. Durch die Integration von WhatsApp mit Calendesk und die entsprechende Einstellung des Standorts für Ihren Service enthalten Ihre bestätigten Buchungen einen individuellen Terminlink für Sie und Ihren Kunden.",
  whatsapp_description_short:
    "Schnelles, einfaches und sicheres Telefonieren und Versenden von Nachrichten auf Telefonen auf der ganzen Welt.",
  whatsapp_connect_button_title: "Verbinden Sie Ihr WhatsApp",
  calendar_public_label:
    "Zeigen Sie private Ereignisbeschreibungen nach dem Importieren an",
  calendar_public_tooltip:
    "Calendesk kann Beschreibungen privater Ereignisse aus Ihren überwachten Kalendern anzeigen. Die Beschreibungen sind für alle Ihre Kollegen sichtbar, die Zugriff auf Ihre Buchungen und Kalendersperren haben. Das Ändern dieser Option wirkt sich nur auf zukünftige heruntergeladene Ereignisse aus.",
  outlook_calendar_add_title:
    "Buchungen und Sperrungen an den Outlook Kalender senden",
  outlook_calendar_add_info:
    "Wählen Sie den Outlook-Kalender, zu dem neue Buchungen und Kalendersperren aus Calendesk hinzugefügt werden sollen.",
  outlook_calendar_watch_info:
    "Wählen Sie aus, aus welchen Outlook-Kalendern wir Termine herunterladen sollen. An ihrer Stelle werden im Calendesk-Kalender Sperren erstellt, um Ihre Verfügbarkeit zu deaktivieren.",
  outlook_calendar_auth_success_title: "Korrekte Microsoft-Autorisierung",
  outlook_calendar_disconnect_dialog_title: "Outlook Kalender trennen",
  outlook_calendar_disconnect_dialog_info:
    "Sind Sie sicher, dass Sie die Verbindung zu Outlook Calendar trennen möchten? Ihre zukünftigen Calendesk-Besuche und -Sperren werden nicht mehr an Outlook Calendar gesendet.",
  outlook_calendar_disconnect_success_message:
    "Die Verbindung zum Kalender wurde korrekt getrennt.",
  microsoft_supported_tools:
    "Unterstützte Integrationen für Ihr Microsoft-Konto",
  whatsapp_add_title: "Ihre WhatsApp-Nummer",
  whatsapp_add_info:
    "Geben Sie die Telefonnummer ein, die Sie verwenden möchten, um WhatsApp mit Ihren Kunden zu kommunizieren. Die Nummer wird nur Ihrem Calendesk-Konto zugeordnet.",
  whatsapp_disconnect_dialog_title: "Trennen Sie die WhatsApp-Nummer",
  whatsapp_calendar_disconnect_dialog_info:
    "Sind Sie sicher, dass Sie die WhatsApp-Integration wirklich trennen? Ihre zukünftigen Besuche bei Calendesk werden nicht mehr mit dieser WhatsApp-Nummer verknüpft.",
  whatsapp_calendar_disconnect_success_message:
    "Die Verbindung zu Whatsapp wurde korrekt getrennt.",
  recurrence_event: "Wiederkehrend",
  google_services_description_short:
    "Zugriff auf Buchungen in Ihrem Google-Kalender. Verwenden Sie Google Meet für Videoanrufe mit Kunden.",
  microsoft_services_description_short:
    "Senden Sie Buchungen an Ihren Outlook-Kalender. Verbinden Sie sich mit Kunden über Microsoft Teams oder Skype.",
  notifications_running_out_of_notifications:
    "Ihre E-Mail-/SMS-Benachrichtigungen gehen zur Neige. Laden Sie auf, um Ihre Kunden über Buchungen auf dem Laufenden zu halten.",
  choose_email_notifications_package:
    "Wählen Sie ein E-Mail-Benachrichtigungspaket aus",
  choose_sms_notifications_package:
    "Wählen Sie ein SMS-Benachrichtigungspaket aus",
  notifications_packages_info:
    "Benachrichtigungspakete laufen nicht ab. Sie können sie verwenden, solange Sie Calendesk verwenden. Paketbenachrichtigungen werden verwendet, wenn Ihnen die Paketbenachrichtigungen ausgehen, die Sie jeden Monat im Rahmen Ihres Plans erhalten.",
  never_expires: "Gültig für immer",
  email_package: "E-Mail Paket",
  sms_package: "SMS Paket",
  email_packages: "E-Mail Pakete",
  sms_packages: "SMS Pakete",
  extra_notification: "zusätzliche Benachrichtigungen",
  go_to_notifications: "Gehen Sie zu Benachrichtigungen",
  days: "Tage",
  hours: "Stunden",
  hour: "Stunde",
  minutes_full: "Minuten",
  seconds: "Sekunden",
  black_friday_title: "Black Friday 2022!",
  black_friday_subtitle: "Vergessen Sie Ihre Kunden nicht!",
  black_friday_ends_info: "Bis zum Ende der Aktion",
  black_friday_info_1:
    "Wir haben eine Sonderaktion für E-Mail- und SMS-Benachrichtigungen vorbereitet! Erhalten Sie zusätzliche Benachrichtigungen beim Kauf von E-Mail oder SMS Paketen.",
  black_friday_info_2:
    "Nutzen Sie die Möglichkeit, E-Mail und SMS Benachrichtigungen an Ihre Kunden zu senden und bereiten Sie ihnen etwas Besonderes vor!",
  show_canceled_bookings: "Stornierte Buchungen im Kalender anzeigen",
  upselling_title: "Upselling",
  upselling_info:
    "Steigern Sie Ihre Einnahmen und die Kundenzufriedenheit durch das Angebot zusätzlicher Produkte oder Dienstleistungen. Sie können Produkte auswählen, die zusätzlich zur Buchung eines Dienstes angeboten werden. Dies ist eine gute Möglichkeit, Upselling zu betreiben, das heißt, zusätzliche Artikel zu verkaufen, wenn der Kunde eine Dienstbuchung vornimmt.",
  upselling_info_2:
    'Die Funktion "Produkte" (Upselling) kann Ihrem Unternehmen während des Buchungsprozesses viele Vorteile bringen. Durch das Anbieten zusätzlicher Produkte oder Dienstleistungen können Sie sowohl den Umsatz als auch die Kundenzufriedenheit steigern. Ausgewählte Produkte können Sie bestimmten Dienstleistungen zuordnen, indem Sie sie im Tab "Angebot/Dienstleistungen" bearbeiten.',
  simple_store_products: "Produkte",
  add_simple_store_products_with_plus:
    'Keine Produkte. Fügen Sie ein Produkt hinzu, indem Sie rechts auf das "+" klicken.',
  product_has_transactions_delete_error:
    "Sie können ein bereits verkauftes Produkt mit Zahlungstransaktionen nicht löschen.",
  additional_information: "Zusätzliche Informationen",
  simple_product_name_example: "Beispiel Produktname",
  simple_product_description_example:
    "Sie holen das Produkt bei Ihrem Besuch ab.",
  simple_store_product_description_title:
    "Zusätzliche Produktinformationen nach dem Kauf",
  simple_store_product_description_info:
    "Zusätzliche Informationen, die nach dem Kauf des Produkts in einer E-Mail-Benachrichtigung bereitgestellt werden.",
  pay_for_products: "Bezahlen Sie für die Produkte\t",
  add_transaction: "Transaktion hinzufügen",
  additional_products: "Zusätzliche Produkte",
  christmas_wishes_title: "Frohe Weihnachten 🎅🌟",
  christmas_wishes_subtitle_1:
    "Wir wünschen Ihnen nicht nur Freude und Glück während der Weihnachts- und Neujahrsferien, sondern auch alles Gute und die Erfüllung Ihrer Träume. Wir hoffen, dass Sie diese Zeit mit Ihren Lieben verbringen und jeden Moment genießen. Möge das neue Jahr viel Freude und Zufriedenheit mit Ihren Erfolgen bringen.",
  christmas_wishes_subtitle_2: "Calendesk-Team 🤗",
  user_requested_employee_title: "Der Kunde hat einen Mitarbeiter ausgewählt.",
  subscription_renews_info: "Ihr Abonnement wird verlängert",
  product_active: "Produkt ist aktiv",
  simple_store_product_additional_information_title:
    "Zusätzliche Produktinformationen vor dem Kauf",
  simple_store_product_additional_information_description:
    "Zusätzliche Informationen werden während des Kaufs des Produkts angezeigt, wenn das Produkt unabhängig (außerhalb des Buchungssystems) gekauft wird.",
  upselling_description_title: "Zusätzlicher Text",
  upselling_description_subtitle:
    "Zusätzlicher Text, den wir bei der Buchung des Dienstes anzeigen. Sie können es zum Beispiel verwenden, um zusätzliche Produkte zu bewerben.",
  wb_init_calendar_title_example:
    "Wählen Sie eine Leistung aus, um zum Buchungskalender zu gelangen 👇",
  wb_init_calendar_title: "Titel bei der Auswahl eines Dienstes",
  wb_initial_service_selection: "Erste Seite zur Auswahl der Dienste anzeigen",
  add_to_google_calendar_button: "Zu Google Kalender hinzufügen",
  add_to_google_ical_outlook_button: "Zu iCal/Outlook hinzufügen",
  add_to_calendar_variable_title: 'Schaltflächen "Zum Kalender hinzufügen"',
  manage_booking_button_title: "Verwalten Sie Ihre Buchung",
  manage_bookings_button_title: "Gehen Sie zu Buchungen",
  manage_booking_button_variable_title:
    'Schaltfläche "Verwalten Sie Ihre Buchung"',
  manage_bookings_button_variable_title: 'Schaltfläche "Gehe zu Buchungen"',
  wb_language_not_supported_info:
    "Leider wird die von Ihnen gewählte Sprache vom Website-Builder von Calendesk nicht vollständig unterstützt. Aber keine Sorge, Ihre Kunden werden immer noch übersetzte Kleinigkeiten sehen können, die Sie nicht ändern können.",
  group_booking_title: "Gruppenbuchung",
  edit_group_booking_title: "Bearbeitung einer Gruppenbuchung",
  edit_single_instance_group_booking_title:
    "Bearbeitung einer Einzelbuchung aus einer Gruppe",
  group_booking_description:
    "Ein Mitarbeiter und viele Kunden während des Treffens",
  edit_booking_title: "Bearbeitung einer Buchung",
  single_booking_description:
    "Ein Mitarbeiter und ein Kunde während des Treffens",
  blackout_date_title: "Kalendersperre",
  edit_unavailable_booking_slot_title: "Bearbeitung einer Kalendersperre",
  blackout_date_description:
    "Blockieren Sie die Möglichkeit für Kunden, Buchungen vorzunehmen",
  select_service_from_list_title: "Dienstleistung auswählen",
  select_services_title: "Wählen Sie Dienstleistungen",
  availability_window_button_title: "Siehe Mitarbeiterverfügbarkeit",
  booking_form_service_question:
    "Für welche Dienstleistung möchten Sie ein Meeting buchen?",
  booking_form_employee_question: "Wer soll das Meeting leiten?",
  booking_form_user_question: "Mit wem wird das Meeting sein?",
  booking_form_date_question: "Wann soll das Meeting stattfinden?",
  booking_location_question: "Wo soll das Meeting stattfinden?",
  booking_form_additional_question: "Zusätzliche Einstellungen",
  blackout_date_form_date_question: "Wann möchten Sie den Kalender blockieren?",
  blackout_date_form_employee_question:
    "Wessen Kalender möchten Sie blockieren?",
  available_hours_title:
    "Die nächsten verfügbaren Termine des ausgewählten Tages für Mitarbeiter (@{date}):",
  update_recurring_event_title: "Bearbeitung eines wiederkehrenden Ereignisses",
  update_recurring_event_question:
    "Dies ist ein wiederkehrendes Ereignis. Was möchten Sie bearbeiten?",
  event_updated_no_notifications_label:
    "Informieren Sie nicht über die Änderung des Besuchs",
  type: "Typ",
  event_type: "Veranstaltungstyp",
  event_type_booking: "Buchung",
  event_type_group_booking: "Gruppenbuchung",
  event_type_unavailable_booking_slot: "Kalendersperre",
  calendesk: "Calendesk",
  blackout_date_default_value: "Calendesk Kalendersperre",
  booking_recurrence_description:
    "jeden dritten Tag, beginnend am 10.06.2023, 10 mal",
  manage_booking_event: "Buchung",
  manage_unavailable_booking_slot_event: "Kalendersperre",
  wants_set_end_time_label:
    "Ich möchte das Ende des Treffens manuell festlegen",
  wants_set_end_time_info:
    "Wählen Sie die Option, die Endzeit des Meetings manuell festzulegen, anstatt die Standarddienstlänge.",
  new_booking_title: "Neue Buchung",
  new_unavailable_booking_slot_title: "Neue Kalendersperre",
  show_more_employees: "Nächste anzeigen (@{number})",
  select_employee_label: "Mitarbeiter auswählen",
  select_employees_label: "Mitarbeiter auswählen",
  select_customer_label: "Kunden auswählen",
  select_customers_label: "Kunden auswählen",
  booking_from_group: "Buchung aus einer Gruppe",
  bookings_in_group: "Buchung in einer Gruppe",
  event_preview_type_booking: "Vorschau der Buchung",
  event_preview_type_unavailable_booking_slot: "Vorschau der Kalendersperre",
  event_preview_type_group_booking: "Vorschau der Gruppenbuchung",
  event_history_title: "Änderungsverlauf",
  events: "Veranstaltungen",
  download_bookings_csv_title: "CSV herunterladen (Buchungen)",
  download_unavailable_booking_slots_csv_title:
    "CSV herunterladen (Kalendersperren)",
  employee_is_public_filter_title: "Buchungen von Kunden",
  employee_is_public_filter_visible: "Nimmt Buchungen an",
  employee_is_public_filter_hidden: "Nimmt keine Buchungen an",
  wb_calendar_min_date: "Zeige Verfügbarkeit ab dem Tag",
  wb_calendar_max_date: "Zeige Verfügbarkeit bis zum Tag",
  delete_user_subscriptions: "Kundenabonnements entfernen",
  cancel_user_subscriptions: "Kundenabonnements stornieren",
  payment_date: "Zahlungsdatum",
  active_subscriptions: "Aktive Abonnements",
  inactive_subscriptions: "Inaktive Abonnements",
  newsletter_new_record_info:
    "Wir senden eine Nachricht an die angegebene E-Mail-Adresse, durch die der Benutzer sie bestätigen kann.",
  google_calendar_permissions_error:
    "Calendesk hat keinen vollen Zugriff auf Ihre Kalenderliste. Es ist möglich, dass Sie die erforderlichen Berechtigungen während der Integration mit Google Calendar abgewählt haben. Versuchen Sie, die Integration zu trennen und erneut zu verbinden. Wenn Sie uns Ihre Kalenderliste nicht zur Verfügung stellen möchten, geben Sie manuell die eindeutige Kalender-ID-Nummer ein.",
  description_too_long: "Die eingegebene Beschreibung ist zu lang",
  identity_number_label: "Interne Nummer",
  sales: "Verkauf",
  offer: "Angebot",
  tools: "Werkzeuge",
  activity: "Aktivität",
  billing: "Abrechnungen",
  notification_activity_info:
    "Hier findest du die Historie der per E-Mail, SMS oder Push-Benachrichtigung an deine Kunden und Mitarbeiter gesendeten Nachrichten.",
  root_account_title: "Hauptkonto",
  blackout_date_move_to_another_resource_error:
    "Sie können die Kalendersperre nicht ändern, indem Sie sie einem anderen Mitarbeiter zuweisen. Versuchen Sie, die Sperre zu entfernen und erneut hinzuzufügen.",
  cancel_group_bookings_title: "Ganze Gruppe stornieren",
  delete_group_bookings_title: "Ganze Gruppe löschen",
  duplicate_group_bookings_title: "Ganze Gruppe duplizieren",
  logs_title: "Aktivitätsprotokolle",
  logs_type_title: "Änderungstyp",
  logs_diff_before_title: "Änderung vorher",
  logs_diff_after_title: "Änderung nach",
  logs_diff_name: "Name",
  logs_diff_surname: "Nachname",
  logs_diff_email: "E-Mail",
  logs_diff_status: "Status",
  logs_diff_date_of_birth: "Geburtsdatum",
  logs_diff_gender: "Geschlecht",
  logs_diff_identity_number: "Interne Nummer",
  logs_diff_firebase_token: "Interner Token (PUSH)",
  logs_diff_draft_uuid: "Entwurfs-ID",
  logs_diff_stripe_id: "Stripe ID",
  logs_diff_fakturownia_id: "eFakturierung ID",
  logs_diff_pm_type: "Kartentyp",
  logs_diff_pm_last_four: "Die letzten vier Ziffern der Karte",
  logs_diff_payment_method_id: "Zahlungsmethode ID",
  logs_diff_gdpr_fields: "DSGVO-Felder",
  logs_diff_email_verified_at: "E-Mail-Verifizierung",
  logs_diff_date_of_names_day: "Namenstag Datum",
  logs_diff_card_number: "Kartennummer",
  logs_diff_description: "Beschreibung",
  logs_diff_start_date: "Startdatum",
  logs_diff_end_date: "Endedatum",
  logs_diff_start_time: "Startzeit",
  logs_diff_end_time: "Endzeit",
  logs_diff_control: "Kontrollnummer",
  logs_diff_custom_fields: "Benutzerdefinierte Felder",
  logs_diff_google_calendar_event_id: "Google Calendar Event ID",
  logs_diff_google_meet_url: "Link zu Google Meet",
  logs_diff_paid: "Zahlung",
  logs_diff_skip_create_notifications:
    "Benachrichtigungen zur Erstellung überspringen",
  logs_diff_skip_update_notifications:
    "Benachrichtigungen zur Aktualisierung überspringen",
  logs_diff_skip_delete_notifications:
    "Benachrichtigungen zur Löschung überspringen",
  logs_diff_group_id: "Gruppen-ID",
  logs_diff_service_location_id: "Standort-ID",
  logs_diff_paid_with_user_subscription_id:
    "Bezahlt mit der Abonnement-ID des Benutzers",
  logs_diff_recurrence_params: "Wiederholungsparameter",
  logs_diff_payment_method: "Zahlungsmethode",
  logs_diff_service_id: "Service-ID",
  logs_diff_service_type_id: "Service-Option-ID",
  logs_diff_employee_id: "Mitarbeiter-ID",
  logs_diff_user_id: "Kunde ID",
  logs_diff_multi_slot_group_id: "Gruppenbuchungs-ID",
  logs_diff_zoom_meeting_id: "Zoom Meeting ID",
  logs_diff_zoom_start_url: "Zoom Start-URL",
  logs_diff_zoom_join_url: "Zoom Beitritts-URL",
  logs_diff_created_by: "Erstellt von",
  logs_diff_updated_by: "Aktualisiert von",
  logs_diff_customer_time_zone: "Zeitzone des Kunden",
  logs_diff_zoom_occurrence_id: "Einzelne Zoom-Ereignis-ID",
  logs_diff_customer_time_notation: "Zeitnotation des Kunden",
  logs_diff_outlook_calendar_event_id: "Outlook Calendar Event ID",
  logs_diff_booking_payment_transaction_id:
    "Zahlungstransaktions-ID für die Buchung",
  logs_diff_teams_url: "Teams URL",
  logs_diff_skype_url: "Skype URL",
  logs_diff_employee_whatsapp_url: "WhatsApp-URL des Mitarbeiters",
  logs_diff_customer_whatsapp_url: "WhatsApp-URL des Kunden",
  logs_diff_image_id: "Bild-ID",
  logs_diff_data: "Daten",
  logs_diff_success_url: "Erfolgs-URL",
  logs_diff_recurring_interval: "Regelmäßig wiederholen",
  logs_diff_service_limit: "Service-Limit",
  logs_diff_tax: "Steuer",
  logs_diff_price: "Preis",
  logs_diff_quantity: "Menge",
  logs_diff_additional_information: "Zusätzliche Informationen",
  logs_diff_rules: "Regeln",
  logs_diff_canceled_at: "Stornierungsdatum",
  logs_diff_wants_invoice: "Rechnung",
  logs_diff_cancel_url: "Stornierung/Fehler-URL",
  logs_diff_require_billing_data: "Angaben zur MWSt-Rechnung erforderlich",
  logs_diff_disable_robot_indexing:
    "Suchmaschinen-Robots nicht erlauben, dieses Abonnement zu indexieren",
  logs_diff_only_company_invoice_data_type:
    "Fordern Sie vollständige Daten für die MWSt-Rechnung (Umsatzsteuernummer, Firmenname, Adresse) an",
  logs_diff_stripe_subscription_id: "Stripe-Abonnement-ID",
  logs_type_created: "Erstellung",
  logs_type_updated: "Ändern",
  logs_type_deleted: "Löschen",
  logs_subject_type: "Objekttyp",
  logs_subject_type_user: "Benutzer",
  logs_subject_type_booking: "Buchung",
  logs_subject_type_subscription: "Abonnement",
  logs_subject_type_employee: "Mitarbeiter",
  logs_subject_type_unavailablebookingslot: "Kalendersperre",
  logs_subject_type_service: "Dienstleistung",
  logs_subject_type_servicetype: "Variant",
  logs_subject_type_servicelocation: "Verknüpfung von Standort und Service",
  logs_subject_type_serviceemployee: "Verknüpfung von Mitarbeiter und Service",
  logs_subject_type_servicesimplestoreproduct:
    "Verknüpfung von Produkt und Service",
  logs_subject_type_serviceresource: "Verknüpfung von Ressource und Service",
  logs_subject_type_bookingtag: "Verknüpfung von Tag und Buchung",
  logs_subject_type_servicesubscription:
    "Verknüpfung von Abonnement und Service",
  logs_subject_type_bookingsimplestoreproduct:
    "Verknüpfung von Produkt und Buchung",
  logs_subject_type_usergroup: "Verknüpfung von Gruppe und Benutzer",
  logs_subject_type_category: "Kategorie",
  logs_subject_type_userpreferences: "Benutzereinstellungen",
  logs_subject_type_employeeworkinghour: "Arbeitsstunden des Mitarbeiters",
  logs_subject_type_group: "Gruppe",
  logs_subject_type_permission: "Berechtigung",
  logs_subject_type_role: "Rolle",
  logs_subject_type_subscriptionpaymenttransaction: "Abonnementzahlung",
  logs_subject_type_simplestoreproducttransaction: "Produktzahlung",
  logs_subject_type_bookingpaymenttransaction: "Buchungszahlung",
  logs_subject_type_usersubscription: "Benutzerabonnement",
  logs_subject_type_apikey: "API-Schlüssel",
  logs_subject_type_availabilityschedule: "Verfügbarkeitsplan",
  logs_subject_type_tag: "Etikett",
  logs_subject_type_employeewatchedgooglecalendar: "Google Kalender",
  logs_subject_type_employeewatchedoutlookcalendar: "Outlook Kalender",
  logs_subject_type_resource: "Ressource",
  logs_subject_type_product: "Produkt",
  logs_subject_type_settings: "Einstellungen",
  logs_subject_type_simplestoreproduct: "Produkt",
  logs_subject_type_address: "Adresse",
  logs_subject_type_phone: "Telefon",
  logs_subject_type_templatesettings: "Vorlageneinstellungen",
  logs_subject_type_location: "Standort",
  logs_changer_name: "Änderer",
  employee_not_available_in_this_service_error:
    "Der Mitarbeiter ist diesem Service nicht zugeordnet.",
  logs_diff_booking_id: "Buchungs-ID",
  logs_diff_transaction_id: "Transaktionsnummer / Kommentar",
  logs_diff_provider_tag: "Methode",
  logs_diff_paid_at: "Bezahlt",
  logs_diff_promo_code: "Rabattcode",
  logs_diff_currency: "Währung",
  logs_diff_email_notification_booking_created: "Buchungsbestätigung - E-mail",
  logs_diff_email_notification_booking_updated: "Buchungsänderung - E-Mail",
  logs_diff_email_notification_booking_deleted: "Buchungsstornierung - E-Mail",
  logs_diff_email_notification_booking_reminder: "Buchungserinnerung - E-Mail",
  logs_diff_sms_notification_booking_created: "Buchungsbestätigung - SMS",
  logs_diff_sms_notification_booking_updated: "Buchungsänderung - SMS",
  logs_diff_sms_notification_booking_deleted: "Buchungsstornierung - SMS",
  logs_diff_sms_notification_booking_reminder: "Buchungserinnerung - SMS",
  logs_diff_model_id: "Verknüpft mit ID",
  logs_diff_e164: "Telefon",
  logs_diff_street: "Strasse",
  logs_diff_postal_code: "Postleitzahl",
  logs_diff_city: "Ort",
  logs_diff_country_iso_code: "Land",
  logs_diff_vat_iso_prefix: "MwSt.-Präfix",
  logs_diff_tax_number: "MwSt/USt",
  logs_diff_category_id: "Kategorie-ID",
  logs_diff_duration: "Dauer",
  logs_diff_booking_time_before: "Reservierung den Service mindestens",
  logs_diff_cancel_time_before: "Stornierung den Service bis spätestens",
  logs_diff_change_time_before: "Umbuchung den Service bis spätestens",
  logs_diff_max_people: "Anzahl der Plätze",
  logs_diff_slots_every:
    "Zeigen Sie den Kunden die Möglichkeit, zu buchen alle",
  logs_diff_allow_online_payment:
    "Aktivieren Sie Online-Zahlungen für diesen Dienst",
  logs_diff_awaiting_booking_payment_time: "Zeit für die Bezahlung der Buchung",
  logs_diff_payment_required: "Vorauszahlung",
  logs_diff_order_position: "Position",
  logs_diff_max_booking_time: "Buchung den Dienst frühestens",
  logs_diff_upselling_description: "Zusätzlicher Text",
  logs_diff_type: "Typ",
  logs_diff_interval_count: "Wiederholen alle",
  logs_diff_color: "Farbe",
  logs_diff_is_public: "Nimmt Buchungen an",
  logs_diff_title: "Titel",
  logs_diff_facebook: "Link zum Facebook-Profil",
  logs_diff_instagram: "Link zum Instagram-Profil",
  logs_diff_youtube: "Link zum YouTube-Profil",
  logs_diff_twitter: "Link zum Twitter-Profil",
  logs_diff_availability_schedule_id: "Verfügbarkeitsplan ID",
  logs_diff_fakturownia_invoice_id: "eFakturierung ID",
  logs_diff_invoice_number: "Rechnungsnummer",
  logs_diff_user_subscription_id: "Kundenabonnement-ID",
  logs_diff_uuid: "ID",
  logs_diff_id: "ID",
  logs_diff_tag_id: "Tag-ID",
  logs_diff_timezone: "Zeitzone",
  logs_diff_google_calendar_enabled: "Google Kalender aktiviert",
  logs_diff_zoom_user_email: "Zoom-Benutzer-E-Mail",
  logs_diff_google_calendar_id: "Google Kalender-ID",
  logs_diff_teams_business_enabled: "Teams für Unternehmen aktiviert",
  logs_diff_skype_business_enabled: "Skype für Unternehmen aktiviert",
  logs_diff_skype_consumer_enabled: "Skype für Verbraucher aktiviert",
  logs_diff_outlook_enabled: "Outlook aktiviert",
  logs_diff_outlook_calendar_id: "Outlook-Kalender-ID",
  logs_diff_is_google_calendar_public:
    "Beschreibungen im Google Kalender sind sichtbar",
  logs_diff_is_outlook_public:
    "Beschreibungen im Outlook-Kalender sind sichtbar",
  logs_diff_whatsapp_number: "WhatsApp-Nummer",
  logs_diff_break_time_before: "Pause vor",
  logs_diff_break_time_after: "Pause nach",
  logs_diff_only_subscriptions: "Nur Abonnements",
  logs_diff_max_user_bookings: "Maximale Benutzerbuchungen",
  logs_diff_simple_store_product_id: "Produkt-ID",
  logs_diff_resource_id: "Ressourcen-ID",
  logs_diff_subject: "Titel",
  logs_diff_content: "Inhalt",
  logs_diff_type_id: "Typ-ID",
  subject_id: "ID-Nummer",
  logs_diff_subscription_id: "Abonnent-ID",
  logs_subject_type_notificationtemplate: "Benachrichtigungsvorlagen",
  no_integration_employee_warning:
    "Die Integrationen des ausgewählten Mitarbeiters erlauben es nicht, ein Meeting an dem gewählten Ort abzuhalten. Der Meeting-Link wird nicht generiert. Ändern Sie den Ort oder wählen Sie einen anderen Mitarbeiter.",
  single_booking_in_group_edit_warning:
    "Sie bearbeiten eine einzelne Buchung, die einer Gruppe zugewiesen ist, die regelmäßige Meetings eingerichtet hat. In diesem Fall können Sie den Service, die Zeit und den Mitarbeiter nicht ändern. Wenn die Buchung veraltet ist, stornieren Sie sie und fügen Sie sie erneut mit den entsprechenden Einstellungen hinzu. Wenn Sie die Einstellungen für die gesamte Gruppe ändern möchten, gehen Sie zur Gruppenbearbeitung.",
  group_booking_update_unavailable_in_calendar_view:
    "Das Bearbeiten einer Gruppenbuchung durch Ziehen und Ablegen ist nicht möglich. Um Änderungen vorzunehmen, klicken Sie auf die Buchung.",
  event_deleting_title: "Löschen von Ereignissen",
  event_deleting_question:
    "Sind Sie sicher, dass Sie das/die ausgewählte(n) Ereignis/se löschen möchten?",
  do_not_group_booking_checkbox_label: "Buchungen nicht gruppieren",
  employee_limit_dialog_info:
    "Ihr aktueller Plan erlaubt es nicht, einen neuen Mitarbeiter hinzuzufügen. Um ein neues Konto zu erstellen, kontaktieren Sie uns bitte über den Chat oder per E-Mail unter",
  tenant_account_frozen_error:
    "Ihr Konto war lange Zeit inaktiv. Wir stellen Ihre Daten wieder her. Bitte versuchen Sie es in ein paar Minuten noch einmal.",
  client_website_bookings_tab: "Registerkarte „Buchungen“",
  client_website_user_subscriptions_tab: "Registerkarte „Abonnements”",
  client_website_payments_tab: "Registerkarte „Zahlung”",
  client_website_my_account_tab: "Registerkarte „Mein Konto”",
  invoice_warning_client_data:
    "Die Mehrwertsteuerrechnung wird nicht ausgestellt, da vollständige Kundendaten für die Rechnung fehlen.",
  invoice_warning_fakturownia_disabled:
    "Die Mehrwertsteuerrechnung wird nicht ausgestellt, da die Integration mit dem eFakturierung-System deaktiviert ist.",
  default_price: "100,00 €",
  added_location_notification: "Standort hinzugefügt",
  updated_location_notification: "Standort aktualisiert",
  add_location_title: "Standort hinzufügen",
  update_location_title: "Standort bearbeiten",
  add_locations_with_plus:
    "Keine Standorte gefunden. Fügen Sie einen Standort hinzu, indem Sie auf der rechten Seite auf „+“ klicken.",
  add_availability_schedule_conditions_title: "Bedingungen hinzufügen",
  edit_availability_schedule_conditions_title: "Bedingungen ändern",
  add_availability_schedule_exception_title: "Ausnahme hinzufügen",
  edit_availability_schedule_exception_title: "Ausnahme ändern",
  availability_schedule_conditions_description:
    "Bedingungen ermöglichen die Zuweisung von Diensten oder Standorten zu spezifischen Zeitbereichen. Das bedeutet, dass wenn ein Kunde den Verfügbarkeitskalender durchsucht, das System automatisch die Verfügbarkeit unter Berücksichtigung zusätzlicher Kriterien wie ausgewählte Dienste oder Standorte überprüft.",
  timezone: "Zeitzone",
  copy_hours_button_title: "Stunden kopieren",
  copy_hours_from_monday_item: "Stunden von Montag kopieren",
  copy_hours_from_tuesday_item: "Stunden von Dienstag kopieren",
  copy_hours_from_wednesday_item: "Stunden von Mittwoch kopieren",
  copy_hours_from_thursday_item: "Stunden von Donnerstag kopieren",
  copy_hours_from_friday_item: "Stunden von Freitag kopieren",
  copy_hours_from_saturday_item: "Stunden von Samstag kopieren",
  copy_hours_from_sunday_item: "Stunden von Sonntag kopieren",
  add_hours_range_button_tooltip:
    "Einen neuen Stundenbereich hinzufügen, den Sie bestimmten Standorten oder Diensten zuweisen können.",
  add_hours_interval_button_tooltip:
    "Innerhalb eines bestehenden Stundenbereichs ein neues Zeitintervall erstellen.",
  delete_date_range_warning_title: "Datumsbereich löschen",
  delete_date_range_warning_description:
    "Sind Sie sicher, dass Sie diesen Datumsbereich löschen möchten? Dies wird Ausnahmen für ausgewählte Tage deaktivieren.",
  add_new_date_range_button_tooltip:
    "Füge Ausnahmen für Wochentage hinzu, um den standardmäßigen Arbeitsplan zu modifizieren.",
  exceptions_date_range_title:
    "Ausnahmen - Daten, die Wochentage überschreiben",
  weekday_rules_title: "Standardarbeitswoche",
  exceptions_date_range_info:
    "Durch das Hinzufügen von Ausnahmedaten können Sie einen speziellen Zeitplan für bestimmte Tage festlegen, wodurch die Standardkonfigurationen für diese Tage ignoriert werden.",
  availability_schedule_assign_to_employees_info:
    "Denken Sie daran, dass jedem Mitarbeiter nur ein Verfügbarkeitsplan zugewiesen werden kann. Das Zuweisen eines neuen Verfügbarkeitsplans an die unten aufgeführten Mitarbeiter wird deren aktuelle Pläne ersetzen.",
  availability_schedule_assign_to_services_info:
    "Denken Sie daran, dass jedem Dienst nur ein Verfügbarkeitsplan zugewiesen werden kann. Dienste können die Verfügbarkeitspläne der Mitarbeiter nutzen oder einen eigenen, dedizierten Plan haben. Das Zuweisen des ausgewählten Verfügbarkeitsplans an die unten aufgeführten Dienste wird deren aktuelle Planungseinstellungen überschreiben. Diese Dienste werden den ausgewählten Verfügbarkeitsplan nutzen.",
  other_settings_title: "Weitere Einstellungen",
  subscription_status_info:
    "Ein aktives Abonnement ermöglicht Kunden den freien Kauf und die Nutzung, während ein inaktives Abonnement keine neuen Käufe zulässt. Kunden, die zuvor ein Abonnement erworben haben, können dieses trotz Deaktivierung unverändert weiter nutzen, haben jedoch nicht die Option, ein neues zu kaufen.",
  subscription_stop_automatic_payments_checkbox_title:
    "Automatische Zahlungen nach Zeitraum stoppen und Abonnement beenden",
  subscription_stop_automatic_payments_after:
    "Beendigung der Zahlungseinzüge nach",
  subscription_allow_users_to_cancel_checkbox_title:
    "Kunden die Möglichkeit geben, aktive Abonnements selbst im Kundenpanel zu kündigen",
  subscription_prices_warning:
    "Denken Sie daran, dass der Betrag des Abonnements und etwaige Kündigungsfristen nach der Erstellung nicht mehr geändert werden können. Wenn Sie diese Parameter ändern möchten, müssen Sie ein neues Abonnement erstellen.",
  cancel_subscription_dialog_title: "Kündigung des Kundenabonnements",
  cancel_single_subscription_dialog_question:
    "Sind Sie sicher, dass Sie das ausgewählte Abonnement kündigen möchten?",
  cancel_multiple_subscription_dialog_question:
    "Sind Sie sicher, dass Sie die ausgewählten Abonnements kündigen möchten?",
  cancel_subscription_immediately_checkbox_title:
    "Ankreuzen, um sofort zu kündigen, ohne auf das Ende des Zeitraums zu warten.",
  cancel_single_subscription_confirm_button: "Abonnement kündigen",
  cancel_multiple_subscription_confirm_button: "Abonnements kündigen",
  selected_ids_label: "Ausgewählte Ids",
  service_location_online: "Online",
  service_location_phone: "Telefon",
  service_location_name: "Name",
  wb_select_locations_title_label: "Text bei der Auswahl des Standorts",
  wb_select_services_title_label: "Text bei der Auswahl des Dienstes",
  wb_select_employees_title_label: "Text bei der Auswahl des Mitarbeiters",
  wb_select_locations_title_example_1: "Wählen Sie einen Standort,",
  wb_select_locations_title_example_2:
    "um verfügbare Dienstleistungen anzuzeigen",
  wb_any_employee_enabled:
    "Automatisch einen zufälligen Mitarbeiter auswählen, den Auswahlschritt überspringen",
  wb_show_expand_collapse_button:
    'Schaltfläche "Mehr Stunden anzeigen" anzeigen',
  wb_show_booked_slots: "Gebuchte Termine im Kalender anzeigen",
  wb_initial_location_select: "Automatisch den ersten Standort auswählen",
  show_more_hours_button: "Mehr Stunden anzeigen",
  add_another_button_title: "Weitere hinzufügen",
  wb_dummy_text_employee_description:
    "Dies ist eine Beispielbeschreibung eines Mitarbeiters. Sie können sie im Calendesk-Admin-Panel einstellen, wenn Sie das Konto des Mitarbeiters bearbeiten. Sie können für verschiedene Mitarbeiter beliebigen Text auswählen.",
  any_person_label: "Beliebige Person",
  selected_time_slots_label: "Ausgewählte Termine",
  logs_diff_phone_id: "Telefon-ID",
  logs_diff_address_id: "Adress-ID",
  user_time_zone_title: "Sprach- und Zeitzoneneinstellungen",
  user_time_zone_locale_info:
    'Mitarbeiter können ihre eigenen Zeitzonen- und Spracheinstellungen festlegen. Buchungen und das Admin-Panel werden gemäß ihrer Auswahl angezeigt. Wenn keine Einstellungen vorgenommen werden, wendet das System automatisch die Standardzeitzone und Sprache für das gesamte Calendesk-Konto an, wie in der Registerkarte "Einstellungen / Plattform" angegeben.',
  app_employees_time_zone_enabled_title:
    "Mitarbeitern erlauben, ihre eigenen Zeitzonen einzustellen",
  app_employees_locale_enabled_title:
    "Mitarbeitern erlauben, die Sprache ihres eigenen Panels einzustellen",
  logs_diff_time_zone: "Zeitzone",
  logs_diff_locale: "Sprache",
  booking_reminders_headline: "Verwalten Sie die Sendezeiten für Erinnerungen",
  booking_reminders_info:
    "Die neue Einstellung der Erinnerungsbenachrichtigung Zeit gilt nur für Buchungen, die nach der Änderung erstellt wurden.",
  booking_reminders_enable_custom_reminder_checkbox:
    "Ich möchte unterschiedliche Sendezeiten für verschiedene Arten von Erinnerungen festlegen",
  booking_reminders_element_type_label: "Art der Benachrichtigung",
  booking_reminders_element_receiver_label: "Empfänger",
  booking_reminders_headline_2: "Aktuelle Erinnerungen",
  booking_reminders_notification_type_1: "E-Mail",
  booking_reminders_notification_type_2: "SMS",
  booking_reminders_notification_type_3: "Push",
  booking_reminders_recipient_type_1: "Kunde",
  booking_reminders_recipient_type_2: "Mitarbeiter",
  booking_reminders_add_new_button: "Neue Erinnerung hinzufügen",
  booking_reminders_no_reminders_info_label:
    "Keine Erinnerungen für Buchungen eingestellt, klicken Sie unten, um die erste Erinnerung hinzuzufügen. 👇",
  booking_reminders_added_successfully_label:
    "Benachrichtigungen wurden erfolgreich hinzugefügt",
  captcha_invalid_error_notification:
    "Captcha-Überprüfung falsch. Versuchen Sie, die Seite neu zu laden, wenn das Problem weiterhin besteht.",
  verify_code_incorrect_error: "Der eingegebene Code ist falsch.",
  verify_code_too_many_requests:
    "Wir können derzeit keinen neuen Code generieren. Versuchen Sie es später erneut oder verwenden Sie den Code, den wir Ihnen gesendet haben.",
  send_again_button_title: "Erneut senden",
  verify_title: "Verifizieren",
  verify_code_title: "Verifizierungscode eingeben",
  verify_code_description:
    "Wir haben einen 6-stelligen Verifizierungscode an Ihre E-Mail gesendet. Bitte geben Sie ihn unten ein:",
  general_settings_title: "Allgemeine Einstellungen",
  bookings_settings_title: "Buchungseinstellungen",
  employees_settings_title: "Mitarbeitereinstellungen",
  create_booking_email_verification_required_checkbox:
    "E-Mail-Bestätigung von nicht angemeldeten Kunden bei der Buchung verlangen",
  verification_invalid_error_notification:
    "Ungültige Überprüfung. Versuchen Sie es später noch einmal.",
  too_many_requests:
    "Ups! Du bist etwas zu schnell. Verlangsame dich und versuche es in einem Moment erneut.",
  notification_category_0: "Systembenachrichtigung",
  notification_category_1: "Anzahlung für Buchung",
  notification_category_2: "Buchungsbestätigung",
  notification_category_3: "Buchungsänderung",
  notification_category_4: "Buchung stornieren",
  notification_category_5: "Buchungserinnerung",
  notification_category_6: "Kontoaktivierung",
  notification_category_7: "Passwort zurücksetzen",
  notification_category_8: "Transaktion im Geschäft",
  notification_category_9: "Links zur mobilen App",
  notification_category_10: "Benutzerdefinierte Benachrichtigung",
  notification_category_11: "Newsletter-Abonnement",
  notification_category_12: "Bestätigungscode",
  notification_category_13: "Kontakt von der Website",
  notification_status_0: "Unbekannt",
  notification_status_1: "Gesendet",
  notification_status_2: "Zugestellt",
  notification_status_3: "Nicht zugestellt",
  notification_status_4: "Fehler aufgetreten",
  errors: "Fehler",
  enter_image_url: "Bild-URL eingeben",
  enter_url: "URL eingeben",
  html_editor_tooltip_bold: "Fett",
  html_editor_tooltip_italic: "Kursiv",
  html_editor_tooltip_underline: "Unterstrichen",
  html_editor_tooltip_strike: "Durchgestrichen",
  html_editor_tooltip_paragraph: "Absatz",
  html_editor_tooltip_heading: "Überschrift",
  html_editor_tooltip_bullet_list: "Aufzählungsliste",
  html_editor_tooltip_code_block: "Code-Block",
  html_editor_tooltip_horizontal_rule: "Horizontale Linie",
  html_editor_tooltip_hard_break: "Zeilenumbruch",
  html_editor_tooltip_add_mage: "Bild hinzufügen",
  html_editor_tooltip_link: "Link hinzufügen",
  html_editor_tooltip_unlink: "Link entfernen",
  html_editor_tooltip_font_size: "Schriftgröße",
  html_editor_tooltip_font_color: "Schriftfarbe",
  html_editor_tooltip_background_color: "Hintergrundfarbe",
  html_editor_tooltip_delete_background_color: "Hintergrundfarbe entfernen",
  html_editor_tooltip_set_html: "HTML einfügen",
  html_editor_tooltip_undo: "Rückgängig",
  html_editor_tooltip_redo: "Wiederholen",
  html_editor_tooltip_align_left: "Links ausrichten",
  html_editor_tooltip_align_right: "Rechts ausrichten",
  html_editor_tooltip_align_center: "Zentrieren",
  html_editor_tooltip_align_justify: "Blocksatz",
  subscription_cancel_reason_info:
    "Hilf uns, unsere Dienstleistungen zu verbessern, damit sie besser deinen Bedürfnissen entsprechen! Teile uns den Grund für die Kündigung deines Abonnements mit, und dank deines Feedbacks werden zukünftige Lösungen besser auf dich abgestimmt sein.",
  subscription_cancel_reason_placeholder: "Deine Meinung",
  subscription_cancel_error:
    "Etwas ist schiefgelaufen. Überprüfe, ob dein Abonnement aktiv ist und ob keine Zahlungen ausstehen. Kontaktiere uns für weitere Informationen.",
  subscription_cancel_data_warning:
    "Nach Beendigung des Abonnements können alle Daten aus dem Buchungssystem unwiderruflich gelöscht werden.",
};
